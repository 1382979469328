import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4'
import './index.css';
import App from './components/App';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: window.location.pathname  });


ReactDOM.render(  
  <App />
   ,
  document.getElementById('root')
);

