import React from "react";
import SeasonCard from './SeasonsCard'
import { Container, Grid } from '@mui/material';

const Seasons=(props)=>{
    console.log(props)

    return(
        <>
            {
                <Container className="movie-card-container" maxWidth="md">
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 10, sm: 20, md: 30 }}>
                    {props.seasons &&
                        props.seasons.map((season, index) => (
                        <Grid item xs={2} sm={4} md={3} key={index}>
                            <SeasonCard
                                key={index}
                                id={props.contentId}
                                season_number={index + 1}
                                season={season}
                                episodes={season[index+1]}
                            />
                        </Grid>
                        ))}
                    </Grid>
              </Container>
              
            }
        </>
    )
}

export default Seasons;