import React, {useState} from 'react'
import {FormControl, MenuItem, Select, InputLabel, TextField, Box, Button, IconButton} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import './SeriesSelect.css'

const SeriesSelect = ({ onChange, seasonNumber, handleAddEpisode, episodeContentName})=>{
    const [addEpisode, setShowAddEpisode] = useState(false)
    const [episodes, setEpisodes] = useState([])

    const handleShowEpisodesInput=()=>{
        if(addEpisode){
            setShowAddEpisode(false)
        }else{
            setShowAddEpisode(true)
        }
    }
    
    // const handleAddEpisode = () => {
    //     setEpisodes((prevEpisodes) => [...prevEpisodes,{ name: "", description: "" },]);
    // };

    const handleDeleteEpisode = (index) =>{
        const updatedEpisodes = episodes.filter((episode, i) => i !== index);
        setEpisodes(updatedEpisodes);
    }

    const handleEpisodeChange = (index, field, value) => {
        const updatedEpisodes = episodes.map((episode, i) =>i === index ? { ...episode, [field]: value } : episode);
        setEpisodes(updatedEpisodes);
    };

    return(
        <div>
            <FormControl fullWidth>
                <InputLabel className='mt-2' id="season-select-label">Seasons</InputLabel>
                <Select className='my-2' labelId="season-select-label" id="season-select-label" value={seasonNumber} label="Season" placeholder='Seasons' variant='outlined'
                    onChange={onChange}>
                    <MenuItem value={1}>Season 1</MenuItem>
                    <MenuItem value={2}>Season 2</MenuItem>
                    <MenuItem value={3}>Season 3</MenuItem>
                    <MenuItem value={4}>Season 4</MenuItem>
                    <MenuItem value={5}>Season 5</MenuItem>
                </Select>

                {/* Add episodes and description */}
                <Button onClick={handleShowEpisodesInput} variant="contained" sx={{backgroundColor:'#2D132C'}} className='add-episode-btn'>Add Episodes</Button>
                {
                    addEpisode && (
                    <Box sx={{ width: '100%', maxWidth: '100%' }} className='mt-2'>
                        <IconButton sx={{border:'1px solid'}} onClick={handleAddEpisode} aria-label="add" color="secondary">
                            <AddIcon />
                        </IconButton>
                    </Box> )
                }
            </FormControl>
        </div>
    )
}

export default SeriesSelect;