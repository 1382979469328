import React from "react";
import { Link } from 'react-router-dom'
import '../../Movies/MovieCard.css'

const SeasonCard = (props)=>{
    const role = props.role
    console.log(props.episodes)

    return (
        <div key={props.movieName}>
            <Link style={{textDecoration:'none'}} to={`/content/${props.id}/season/${props.season_number}/info`} >
                <div className={`card ${role === 'admin' ? 'movie-card-admin' : 'content-season-card'}`}>
                    <div className="card-body">
                        <span style={{fontWeight:'bold'}} className="text-left mt-2">
                            <p>Season Number: {props.season_number}</p>
                          
                            <p>Episode Count: {props.episodes.length}</p>
                        </span>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default SeasonCard;