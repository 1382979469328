import React, { useState, useEffect } from "react";
import SearchBar from './Search/Search'
import NavSide from '../Dashboard/NavSide'
import MovieCard from './Cards';
import { Container, Grid } from '@mui/material';
import { useNavigate } from 'react-router';
import '../Dashboard/NavSide.css'
import { Link } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Divider from '@mui/material/Divider';

const Movies =()=>{
    const navigate = useNavigate()
    const [movies, setMovies] = useState([])

    const getMovies= async (input)=>{
        //get request to content
        const vendorId =  localStorage.getItem('userId')
        const vendorName = localStorage.getItem('vendorName')
        
        const axiosInstance = require('../../axiosConfig')
        //Any space in a vendor name is replaced with a hyphen (-) when stored in DynamoDB. AWS services dont like spaces
        const resp = await axiosInstance.instance.get(`/content/${vendorId}/${vendorName.replace(/[\s!@#$%^&*()_+[\]{};:'",<>?~`]/g, '-')}`)
        
        if(resp.data.status ===  200){
            console.log(resp.data.content)

            let movies = resp.data.content
            setMovies(movies)
        }else{
            setMovies([])
        }
    }

    useEffect(()=>{
        //getMoviesPreview()
        getMovies()
    },[])
   
    const handleGoBack = () => {
		navigate(-1);
	}

    return(
        <div style={{background:'#1F0F25', height:'100vh'}}>
            <NavSide/>
            {/* add movie filter */}
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="mt-5 d-flex justify-content-center">
                            
                            <div className="mr-2" style={{cursor:'pointer'}} onClick={handleGoBack}>
                                <ArrowBackIcon fontSize={'small'} sx={{color: '#fff'}}/>	
                            </div>

                            <div>
                                <h4 className="text-white ms-2">My content</h4>
                                <div className="d-flex ms-2">
                                    <Divider  textAlign="left" sx={{border: "2px solid #C92941", background: '#C92941', borderRadius: '8px', width: '40px', marginRight: '5px', opacity: 1,}}></Divider>
                                    <Divider  textAlign="left" sx={{border: "2px solid #C92941", background: '#C92941', borderRadius: '8px', width: '5px', opacity: 1,}}></Divider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <Container className="movie-card-container" maxWidth='md'>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 10, sm: 20, md: 30 }}>
                    { movies && movies.length > 0 ? movies.map((movie, index)=>{
                        return <Grid item xs={2} sm={4} md={3} key={index}>
                                <MovieCard key={movie.content_id} id={movie.content_id} movieName={movie.content_name} duration={movie.duration} licensedFrom={movie.day_uploaded} hoursWatched={movie.hoursStreamed} 
                                    trending={movie.isTrending ? 'Yes' : 'No'} genre={movie.genre} releaseYear={movie.released} description={movie.content_description} series={movie.isSeries} seasons={movie.isSeries ? movie.seasons : []}/>
                            </Grid>
                    }) : 
                    
                    <div className="text-center" style={{marginLeft:'200px', 
                        background:'#2D132C', 
                        border:'1px solid #C170C1',
                        borderRadius:'10px',
                        padding:'10px',
                        width:'100%',
                        fontWeight:'bold',
                        color:'white'
                    }}>No content yet for vendor. {<Link style={{color:'#C170C1'}} to={'/console'}>Upload ?</Link>}</div>}
                </Grid>
            </Container>
            
        </div>
    )
}

export default Movies;